import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Toast } from 'devextreme-react/toast';

import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { registerStudent } from '../../api/auth';
import 'devextreme-react/lookup';
import { locale } from 'devextreme/localization';
import './CreateAccountForm.scss';
import CheckBox from 'devextreme-react/check-box';
import Aszf from '../aszf/Aszf';

export default function CreateAccountForm() {
  locale('hu-HU');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ASZFAccepted, acceptAszf] = useState(false);
  const [ASZFRead, readAszf] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [positionOf, setPositionOf] = useState('');
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  });
  const formData = useRef({ email: '' });
  const fizmod = [
    { key: 'UT', text: 'Utalás' },
    { key: 'KP', text: 'Készpénz' },
  ];

  const fizType = [
    {
      key: 'DEF',
      text: 'Óránként',
    },
    {
      key: 'HO',
      text: 'Minden hónap vége',
    },
    {
      key: '5BER',
      text: '5 óránként bérlet (-10%)',
    },
    {
      key: '10BER',
      text: '10 óránként bérlet (-10%)',
    },
  ];
  const onClickASZF = (e) => {
    if (!ASZFRead) {
      const type = 'error';
      const message = 'Még nem olvastad el az ÁSZF-ünket!!';
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type,
        message,
      });
    }
  };

  const onASZFCheckboxChanged = (e) => {
    if (ASZFRead) {
      acceptAszf(e.value);
    }
  };

  const onHiding = useCallback(() => {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }, [toastConfig, setToastConfig]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const DataToSave = formData.current;
      setLoading(true);

      const result = await registerStudent(DataToSave);
      setLoading(false);

      if (result.isOk) {
        notify('Sikeres jelentkezés hamarosan keresünk!', 'success', 2000);
      } else {
        notify(result.message, 'error', 2000);
      }
    },
    [navigate]
  );

  const showInfo = useCallback(() => {
    readAszf(true);
    setPositionOf(`#ASZF`);
    setPopupVisible(true);
  }, [setPositionOf, setPopupVisible]);

  const hideInfo = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const getCloseButtonOptions = useCallback(
    () => ({
      text: 'Bezárás',
      stylingMode: 'outlined',
      type: 'normal',
      onClick: hideInfo,
    }),
    [hideInfo]
  );
  const dateEditorOptions = {
    type: 'date',
    displayFormat: 'yyyy.MM.dd',
    useMaskBehavior: true,
    calendarOptions: {
      firstDayOfWeek: 1,
    },
  };
  return (
    <React.Fragment>
      <form className={'create-account-form'} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            caption='Néhány adat, hogy fel tudjuk veled venni a kapcsolatot'
            itemType='group'
            colCount={12}
            colSpan={1}
          >
            <Item dataField='lastName' colSpan={4}>
              <RequiredRule message='Vezetéknév megadása kötelező' />
              <Label text={'Vezetéknév'}></Label>
            </Item>
            <Item dataField='firstName' colSpan={4}>
              <RequiredRule message='Keresztnév megadása kötelező' />
              <Label text={'Keresztnév'}></Label>
            </Item>
            <Item dataField='evfolyam' colSpan={1}>
              <RequiredRule message='Évfolyam megadása kötelező' />
              <Label text={'Évfolyam'}></Label>
            </Item>
            <Item dataField='school' colSpan={3}>
              <Label text={'Iskola'}></Label>
            </Item>
            <Item dataField='lastNameParent' colSpan={4}>
              <RequiredRule message='Vezetéknév megadása kötelező' />
              <Label text={'Szülő vezetékneve'}></Label>
            </Item>
            <Item dataField='firstNameParent' colSpan={4}>
              <RequiredRule message='Keresztnév megadása kötelező' />
              <Label text={'Szülő keresztneve'}></Label>
            </Item>

            <Item
              dataField={'email'}
              editorType={'dxTextBox'}
              editorOptions={emailEditorOptions}
              colSpan={4}
            >
              <RequiredRule message='Email megadása kötelező' />
              <EmailRule message='Email formátuma nem megfelelő' />
              <Label text={'E-mail cím'}></Label>
            </Item>
            <Item dataField='phoneStudent' colSpan={4}>
              <Label text={'Diák telefonszáma'}></Label>
            </Item>
            <Item dataField='phoneParent' colSpan={4}>
              <RequiredRule message='Telefonszám megadása kötelező' />
              <Label text={'Szülő telefonszáma'}></Label>
            </Item>
            <Item
              dataField='birthday'
              colSpan={4}
              editorType={'dxDateBox'}
              editorOptions={dateEditorOptions}
              displayFormat='yyyy.MM.dd'
            >
              <Label text={'Születési idő'}></Label>
            </Item>
          </Item>
          <Item
            caption='Jelöld meg, mely tárgyakból van szükséged segítségre!'
            itemType='group'
            colCount={6}
            colSpan={1}
          >
            <Item dataField='subjectMat' colSpan={1} editorType='dxCheckBox'>
              <Label text={'Matematika'}></Label>
            </Item>
            <Item dataField='subjectFiz' colSpan={1} editorType='dxCheckBox'>
              <Label text={'Fizika'}></Label>
            </Item>
            <Item dataField='subjectInf' colSpan={1} editorType='dxCheckBox'>
              <Label text={'Informatika'}></Label>
            </Item>
            <Item dataField='subjectAngol' colSpan={1} editorType='dxCheckBox'>
              <Label text={'Angol'}></Label>
            </Item>
            <Item dataField='subjectNemet' colSpan={1} editorType='dxCheckBox'>
              <Label text={'Német'}></Label>
            </Item>

            <Item
              dataField='subjectProgramozas'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label text={'Programozás tanfolyam'}></Label>
            </Item>
            <Item
              dataField='subjectEgyetemMat'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label text={'Egyetemi matek'}></Label>
            </Item>
            <Item
              dataField='subjectEgyetemInf'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label text={'Egyetemi Info'}></Label>
            </Item>
            <Item
              dataField='subjectSzakmai'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label text={'Szakmai tárgyak'}></Label>
            </Item>
            <Item
              dataField='subjectFelveteli'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label text={'Felvételi (4/6/8)'}></Label>
            </Item>
            <Item
              dataField='subjectMatekIdegen'
              colSpan={2}
              editorType='dxCheckBox'
            >
              <Label text={'Matematika németül/angolul'}></Label>
            </Item>
            <Item
              dataField='subjectOsztrak'
              colSpan={2}
              editorType='dxCheckBox'
            >
              <Label text={'Osztrák iskolai korrepetálás'}></Label>
            </Item>
            <Item
              dataField='subjectOsztrakEgyetem'
              colSpan={2}
              editorType='dxCheckBox'
            >
              <Label text={'Osztrák egyetem'}></Label>
            </Item>
            <Item dataField='subjectEgyeb' colSpan={2} editorType='dxCheckBox'>
              <Label text={'Egyéb, kérlek a megjegyzésben írd le'}></Label>
            </Item>
            <Item colSpan={6}>
              <div className='policy-info'>
                Az órákkal kapcsolatos egyéb igények
              </div>
            </Item>
            <Item dataField='parosOra' colSpan={2} editorType='dxCheckBox'>
              <Label
                text={'Páros órákat elfogadom (-15%) ha van rá lehetőség'}
              ></Label>
            </Item>
            <Item dataField='csakEgyeni' colSpan={2} editorType='dxCheckBox'>
              <Label text={'Csak egyéniben szeretnék órát'}></Label>
            </Item>
            <Item dataField='masfelOra' colSpan={1} editorType='dxCheckBox'>
              <Label text={'90 perces órát szeretnék'}></Label>
            </Item>
            <Item dataField='duplaOra' colSpan={1} editorType='dxCheckBox'>
              <Label text={'120 perces órát szeretnék'}></Label>
            </Item>

            <Item
              dataField='subjectMegjegyzes'
              colSpan={6}
              editorType='dxTextArea'
            >
              <Label
                text={
                  'Ha bármi kérdésed vagy egyéni igényed lenne, kérlek jelezd itt nekünk és megpróbáljuk megoldani'
                }
              ></Label>
            </Item>
          </Item>
          <Item
            caption='Melyik időpontokban tudnál illetve szeretnél hozzánk járni?'
            itemType='group'
            colCount={7}
            colSpan={1}
          >
            <Item colSpan={7}>
              <div className='policy-info'>
                Kérlek válassz a számodra megfelelő időpontok közül, nem tudjuk
                mindig garantálni, hogy abban az időben adjuk amikor szeretnéd
                ezért inkább többet jelölj be ami elfogadható lehet
              </div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Nap/Napszak</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Hétfő</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Kedd</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Szerda</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Csütörtök</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Péntek</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>Szombat</div>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>9:00-13:00</div>
            </Item>
            <Item dataField='dayHetfoDE' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='dayKeddDE' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='daySzerdaDE' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item
              dataField='dayCsutortokDE'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label visible={false}></Label>
            </Item>
            <Item dataField='dayPentekDE' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='daySzombatDE' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>13:00-17:00</div>
            </Item>
            <Item dataField='dayHetfoDU' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='dayKeddDU' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='daySzerdaDU' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item
              dataField='dayCsutortokDU'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label visible={false}></Label>
            </Item>
            <Item dataField='dayPentekDU' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='daySzombatDU' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item colSpan={1}>
              <div className='policy-info'>17:00-21:00</div>
            </Item>
            <Item dataField='dayHetfoEST' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='dayKeddEST' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item dataField='daySzerdaEST' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item
              dataField='dayCsutortokEST'
              colSpan={1}
              editorType='dxCheckBox'
            >
              <Label visible={false}></Label>
            </Item>
            <Item dataField='dayPentekEST' colSpan={1} editorType='dxCheckBox'>
              <Label visible={false}></Label>
            </Item>
            <Item colSpan={1}>
              <div> </div>
            </Item>
          </Item>
          <Item
            caption='Számlázási adatok'
            itemType='group'
            colCount={12}
            colSpan={1}
          >
            <Item dataField='invoiceZip' colSpan={2}>
              <RequiredRule message='Irányítószám megadása kötelező' />
              <Label text={'Irányítószám'}></Label>
            </Item>
            <Item dataField='invoiceCity' colSpan={3}>
              <RequiredRule message='Település megadása kötelező' />
              <Label text={'Település'}></Label>
            </Item>
            <Item dataField='invoiceStreet' colSpan={7}>
              <RequiredRule message='Utca megadása kötelező' />
              <Label text={'Utca'}></Label>
            </Item>
            <Item
              dataField='payment_method'
              colSpan={3}
              editorType='dxLookup'
              editorOptions={{
                dataSource: fizmod,
                valueExpr: 'key',
                displayExpr: 'text',
              }}
            >
              <RequiredRule message='Fizetési mód megadása kötelező' />
              <Label text={'Milyen módon szeretnél fizetni?'}></Label>
            </Item>
            <Item
              dataField='payment_type'
              colSpan={4}
              editorType='dxLookup'
              editorOptions={{
                dataSource: fizType,
                valueExpr: 'key',
                displayExpr: 'text',
              }}
            >
              <RequiredRule message='Fizetési gyakoriság megadása kötelező' />
              <Label text={'Milyen gyakran küldjünk számlát?'}></Label>
            </Item>
            <Item dataField='taxInvoice' colSpan={5} editorType='dxCheckBox'>
              <Label text={'Céges számlát kérek'}></Label>
            </Item>
          </Item>

          <Item id='ASZF'>
            <div className='aszf-info' onClick={showInfo}>
              Olvasd el figyelmesen a Szolgáltatási feltételeket, csak azután
              tudod elküldeni a jelentkezési lapot! A lenti piros szövegre
              kattintva érhető el.
            </div>
            <div onClick={onClickASZF}>
              <CheckBox
                defaultValue={ASZFAccepted}
                disabled={!ASZFRead}
                text='A jelentkezéssel igazolom, hogy az alábbi linken található ÁSZF-ben foglaltakat elolvastam és elfogadom.'
                onValueChanged={onASZFCheckboxChanged}
                //  onValueChange={onASZFValueChange}
              />
            </div>

            <div className='aszf-info' onClick={showInfo}>
              <strong>
                A foglalkozásokhoz érvényes ÁSZF-ünk itt olvasható.
              </strong>
            </div>
          </Item>
          <Item>
            <div className='policy-info'>
              A jelentkezés elküldéséről e-malben értesítünk, ha nem kaptál
              értesítőt vagy nem jelentkezünk 48 órán belül kérlek jelezd nekünk
              a<strong> +36 70 94225533 </strong>-as telefonszámon.
            </div>
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={'100%'}
              type={'default'}
              useSubmitBehavior={true}
              disabled={!ASZFAccepted}
            >
              <span className='dx-button-text'>
                {loading ? (
                  <LoadIndicator
                    width={'24px'}
                    height={'24px'}
                    visible={true}
                  />
                ) : (
                  'Regisztráció elküldése'
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
      <Popup
        visible={popupVisible}
        onHiding={hideInfo}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title='Szolgáltatási feltételek'
        container='.dx-viewport'
        width={'80%'}
        height={'80%'}
      >
        <Position at='bottom' my='center' of={positionOf} collision='fit' />

        <ToolbarItem
          widget='dxButton'
          toolbar='bottom'
          location='after'
          options={getCloseButtonOptions()}
        />

        <Aszf />
      </Popup>
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={1500}
      />
    </React.Fragment>
  );
}

const emailEditorOptions = {
  stylingMode: 'filled',
  //placeholder: 'Email',
  mode: 'email',
};
